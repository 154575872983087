import { SELECTED_CANCER_TYPES_ANALYTICS_KEY, SELECTED_CANCER_TYPES_COUNT_ANALYTICS_KEY, DO_ADDITIONAL_GTM_DATALAYER_PUSH } from './src/constants/analytics';
import { SELECTED_CANCER_TYPES_KEY } from './src/store/storage-keys';
import { cancerTypes } from './src/util/data/cancerTypes';
import { mapCancerTypesToKeys } from './src/util/functions';

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    // this foces Telium to fire a page view tag when the gatsby route changes
    // we have to make sure that the window object exists and that the utag is in it
    // we also want to make sure that this does not fire on initial page load
    // so we check to see if a previous url location object exists
    if (window && window.utag && prevLocation) {
        
        const selectedCancerTypes = JSON.parse(window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY));
        const analyticsObj = {
            page_url: location.href.replace(location.origin, '')
        };

        if (selectedCancerTypes && !selectedCancerTypes.length) {
            //analyticsObj[SELECTED_CANCER_TYPES_ANALYTICS_KEY] = 'all';
        } else if (selectedCancerTypes) {
            analyticsObj[SELECTED_CANCER_TYPES_ANALYTICS_KEY] = mapCancerTypesToKeys(selectedCancerTypes, cancerTypes);
            analyticsObj[SELECTED_CANCER_TYPES_COUNT_ANALYTICS_KEY] = selectedCancerTypes.length;
        }

        //console.log(location, prevLocation)
        // after noticing that some values are not being passed between
        // page views in analytics, working with the DH team, we've decided
        // to add a delay to make sure that the DOM updates 
        setTimeout(() => {
            // trigger a virtual page view
            window.utag.view(analyticsObj);

            // logging the utag data for debugging purposes
            console.log('analyticsObj -> ', analyticsObj);

            if (window.dataLayer && DO_ADDITIONAL_GTM_DATALAYER_PUSH) {
                analyticsObj.event = SELECTED_CANCER_TYPES_ANALYTICS_KEY;
                window.dataLayer.push(analyticsObj);

                // generic page view workaround
                window.dataLayer.push({
                    event: 'document_location_url', 
                    page_url: analyticsObj.page_url
                });

            } 
        }, 500);
    }

    // if we have a "site entry page load" AND we have the "group" param
    // all we need to do is pass that through to a data layer push (because the page load tag will have already fired)
    if (window && !prevLocation && location.search && DO_ADDITIONAL_GTM_DATALAYER_PUSH) {
        
        const params = new URLSearchParams(location.search);
        const groupsParam = params.get('group');

        if (!groupsParam) return;

        const analyticsObj = {};
        analyticsObj[SELECTED_CANCER_TYPES_ANALYTICS_KEY] = groupsParam;
        
        // not the best way to handle this, but we have to wait until the dataLayer object is on the page
        // given that people need a least a second or two to handle the fact that the page just loaded,
        // this should work
        setTimeout(() => {
                        
            //if (window.utag) window.utag.link(analyticsObj);
            // window.sessionStorage.setItem(PREVIOUSLY_SELECTED_CANCER_TYPES_KEY, window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY))
            if (window.dataLayer) {
                analyticsObj.event = SELECTED_CANCER_TYPES_ANALYTICS_KEY;
                //console.log('analyticsObj --> ', analyticsObj);
                window.dataLayer.push(analyticsObj);
            } 

        }, 2000);
    }    
}

export const shouldUpdateScroll = (_, pluginOptions) => {
    if (window.location.hash === '') { // do not fire for anchor links
        window.setTimeout(() => { window.scrollTo({left: 0, top: 0})}, 10); //scroll to top of page
    }
    // store.dispatch(indicateNotScrolledPastInPageSSI());
    return false;
};
