export const cancerTypes = {
    1: {
        id: 1,
        name: "Renal Cell Carcinoma",
        modifier: "Advanced",
        key: "ren",
        indications: [
            1,
        ]
    },
    2: {
        id: 2,
        name: "Endometrial Carcinoma",
        modifier: "Advanced",
        key: "endo",
        indications: [
            2
        ]
    },
};


export const cancerTypeKeyIdMap = {
    'ren' : [4],
    'all' : [],
}
